import React from "react";
function Index() {
  return (
    <div className="privacy">
      <div className="container">
        <h1>이용 약관의 주요 고지 사항</h1>
        <h2 className="privacy_title">개인정보 처리방침</h2>
        <p>
          ororabrowser (이하 '회사')는 정보주체의 자유와 권리 보호를 위해
          「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게
          개인정보를 처리하며 안전하게 관리하고 있습니다. 이에 「개인정보
          보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및
          기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록
          하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개하고 있습니다.
        </p>

        <h2 className="privacy_title">제1조(개인정보의 처리 목적)</h2>
        <p>
          회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는
          다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는
          사전 동의를 받습니다.
        </p>

        <ul>
          <li>
            <strong>개인정보 서비스명</strong>
          </li>
          <li>개인정보 처리 목적</li>
          <li>회원가입 및 관리</li>
          <li>
            (개인)회원가입, 본인인증, 고객확인, 서비스 이용, 민원 처리, 법령상
            의무이행
          </li>
          <li>휴대전화번호 변경, 계정 잠금해제</li>
          <li>서비스 제공</li>
          <li>고객상담 및 고충처리</li>
          <li>고객상담 및 민원처리</li>
          <li>이벤트 안내</li>
          <li>이벤트 참여를 위한 안내 발송</li>
        </ul>

        <h2 className="privacy_title">제2조(개인정보의 처리 및 보유기간)</h2>
        <h3>가. 서비스 제공을 위해 수집하는 필수적인 개인정보의 항목</h3>
        <p>
          회원가입 또는 서비스 이용과정에서 필요한 개인정보는 이용자의 동의를
          얻어 수집하고 있습니다.
        </p>

        <table>
          <tr>
            <th>수집 유형</th>
            <th>수집목적</th>
            <th>개인정보 수집 항목</th>
          </tr>
          <tr>
            <td>회원가입 및 관리</td>
            <td>서비스 가입 및 이용자 식별 회원관리 및 커뮤니케이션</td>
            <td>
              성명, 성별, 생년월일, 휴대전화번호
              <br />
              회원탈퇴 시 파기
            </td>
          </tr>
          <tr>
            <td>회원가입 및 관리</td>
            <td>회원가입 및 본인인증</td>
            <td>
              [필수] 이메일, 패스워드
              <br />
              회원탈퇴 시 파기
            </td>
          </tr>
          <tr>
            <td>실제 소유자 확인</td>
            <td>[필수] 담당자 이메일, 담당자 휴대전화번호, 소속 국가</td>
            <td>문의 가입종료 후 30일간 보관</td>
          </tr>
          <tr>
            <td>재화 또는 서비스</td>
            <td>고객확인</td>
            <td>
              [필수] 성명, 생년월일, 성별, 이동통신사 정보, 휴대전화번호,
              이메일, CI(이용자 식별정보), DI(중복 확인 정보),
              주민등록번호(외국인의 경우 외국인등록번호) 또는 운전면허번호 및
              일련번호, 발급일자, 은행명, 계좌번호, 국적(외국인의 경우),
              우편번호, 자택주소, 직종, 직장명, 직장주소
              <br />
              회원탈퇴 이후 5년
            </td>
          </tr>
          <tr>
            <td>휴대전화번호 변경</td>
            <td>[필수] 성명, 기존 휴대전화번호, 새롭게 변경된 휴대전화번호</td>
            <td>회원탈퇴 이후 5년</td>
          </tr>
          <tr>
            <td>계정 잠금해제</td>
            <td>
              [필수] 성명, 생년월일, 성별, 통신사, 휴대전화번호, 본인확인 불가
              시 신분증 사본(주민등록번호 뒷자리 마스킹)
            </td>
            <td>회원탈퇴 이후 5년</td>
          </tr>
        </table>

        <h3>
          나. 회사는 정보주체의 개인정보를 추가적인 이용 및 제공하지 않습니다.
        </h3>
        <p>
          추후에 수집 목적과 합리적으로 관련된 범위에서 정보주체 동의 없이
          개인정보를 이용하거나 제공할 수 있습니다. 이때 아래의 항목에 대해
          종합적으로 고려하여 이용 및 제공하며 이에 대한 기준과 내용을 개인정보
          처리방침을 통해 공개할 예정입니다.
        </p>

        <ul>
          <li>
            ① 당초 수집 목적과 합리적으로 관련된 범위에서 정보주체에게 불이익이
            발생하는지 여부
          </li>
          <li>
            ② 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의
            추가적인 이용 또는 제공에 대한 예측 가능성이 있는지 여부
          </li>
          <li>③ 정보주체의 이익을 부당하게 침해하는지 여부</li>
          <li>
            ④ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
          </li>
        </ul>

        <h3>다. 개인정보의 수집 방법</h3>
        <ul>
          <li>
            ① 웹 홈페이지 및 어플리케이션을 통해 이용자가 직접 입력하는 정보
            수집
          </li>
          <li>
            ② 온/오프라인 고객센터 및 실시간 1:1채팅 등 상담 채널을 통한 상담
            과정에서 이메일/온라인 등을 통한 수집
          </li>
          <li>③ 제휴사에서 이용자의 동의를 얻고 전달된 정보 수집</li>
          <li>④ 생성정보 툴을 통한 수집</li>
        </ul>

        <h3>라. 수집근거 : 이용자의 동의 및 법령</h3>

        <h3>마. 보유기간</h3>
        <p>
          ① 투자자산 거래와 관련한 개인정보는 수집・이용에 관한 동의일로부터
          투자자산 거래 종료일 이후 5년까지 제1조의 처리 목적을 위하여
          보유・이용됩니다. 다만, 투자자산 거래 종료일 이후에는 투자자산 관련
          사고 조사, 분쟁 해결, 민원 처리, 법령상 의무이행 및 회사의 리스크
          관리를 위하여 보유・이용됩니다.
        </p>
        <p>
          ② 회원가입 및 관리를 목적으로 수집 된 개인정보는 고객의 회원
          가입일로부터 회원 탈퇴일까지 보유・이용됩니다. 다만, 회원탈퇴 이후에는
          제1조의 목적과 관련된 사고 조사, 분쟁 해결, 민원 처리, 법령상 의무이행
          만을 위하여 보유・이용됩니다.
        </p>
        <p>
          ③ 본인명의 진위 여부 확인 등 개인정보의 조회를 목적으로 수집된
          개인정보는 수집・이용에 대한 동의일로부터 고객에 대한 개인정보
          조회・제공 동의의 효력 기간까지 보유・이용됩니다. 다만, 개인정보
          조회・제공 동의의 효력기간 종료 후에는 투자자산 관련 사고 조사, 분쟁
          해결, 민원 처리 및 법령상 의무이행을 위하여 보유・이용됩니다.
        </p>
        <p>
          ④ 상품 및 서비스 홍보 및 판매 권유 등과 관련한 개인정보는 수집・이용에
          관한 동의일로부터 고객에 대한 개인정보 조회・제공 동의의 효력 기간까지
          보유・이용됩니다. 다만, 동의 효력기간 종료 후에는 투자자산 관련 사고
          조사, 분쟁 해결, 민원 처리 및 법령상 의무이행을 위하여
          보유・이용됩니다.
        </p>

        <h3>
          바. 회사의 개인정보 처리방침에도 불구하고, 법령에 의하여 보관하여야
          하는 정보는 아래의 기간 동안 보관됩니다.
        </h3>

        <table>
          <tr>
            <th>구분</th>
            <th>근거법령</th>
            <th>보유기간</th>
          </tr>
          <tr>
            <td>계약 또는 청약철회 등에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>3년</td>
          </tr>
          <tr>
            <td>본인확인에 관한 기록</td>
            <td>정보통신망 이용촉진 및 정보보호 등에 관한 법률</td>
            <td>6개월</td>
          </tr>
          <tr>
            <td>웹사이트 방문에 관한 기록</td>
            <td>통신비밀보호법</td>
            <td>3개월</td>
          </tr>
        </table>

        <h2 className="privacy_title">제3조(정보주체의 권리·의무 및 그 행사 방법)</h2>
        <p>
          정보주체는 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수
          있습니다.
        </p>

        <ul>
          <li>1. 개인정보 열람요구</li>
          <li>2. 오류 등이 있을 경우 정정 요구</li>
          <li>3. 삭제요구</li>
          <li>4. 처리정지 요구</li>
        </ul>

        <p>
          위 사항에 대한 권리 행사는 「개인정보 보호법」 시행령 제41조제1항에
          따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는
          이에 대해 지체 없이 조치하겠습니다.
        </p>

        <p>
          정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는
          회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나
          제공하지 않습니다.
        </p>

        <p>
          위 사항에 대한 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
          대리인을 통하여 하실 수 있습니다. 이 경우 「개인정보 보호법」 시행규칙
          별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        </p>

        <p>
          정보주체는 개인정보 보호법 등 관계 법령을 위반하여 회사가 처리하고
          있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니
          됩니다.
        </p>

        <h2 className="privacy_title">제4조(처리하는 개인정보 항목)</h2>
        <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>

        <ul>
          <li>
            1. 회원가입 및 관리: 성명, 성별, 생년월일, 휴대전화번호, 이메일
          </li>
          <li>
            2. 재화 또는 서비스 제공: 성명, 성별, 생년월일, 휴대전화번호,
            이메일, 주소, 결제정보
          </li>
          <li>
            3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어
            수집될 수 있습니다.
          </li>
          <ul>
            <li>
              IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
            </li>
          </ul>
        </ul>

        <h2 className="privacy_title">제5조(개인정보의 파기)</h2>
        <p>
          회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
          불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        </p>

        <ul>
          <li>1. 파기절차</li>
          <p>
            회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
            보호책임자의 승인을 받아 개인정보를 파기합니다.
          </p>
          <li>2. 파기방법</li>
          <p>
            회사는 전자적 파일 형태로 기록·저장된 개인정보를 기록을 재생할 수
            없도록 로우레벨포맷(Low Level Format) 등의 방법을 이용하여 파기하며,
            종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여
            파기합니다.
          </p>
        </ul>

        <h2 className="privacy_title">제6조(개인정보의 안전성 확보 조치)</h2>
        <p>
          회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 하고 있습니다.
        </p>

        <ul>
          <li>1. 관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등</li>
          <li>
            2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리,
            접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
          </li>
          <li>3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제</li>
        </ul>

        <h2 className="privacy_title">제7조(개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)</h2>
        <p>
          회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를
          저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
        </p>

        <ul>
          <li>1. 쿠키의 사용 목적</li>
          <p>
            이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태,
            인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보
            제공을 위해 사용됩니다.
          </p>
          <li>2. 쿠키의 설치·운영 및 거부</li>
          <p>
            웹브라우저 상단의 도구 → 인터넷 옵션 → 개인정보 메뉴의 옵션 설정을
            통해 쿠키 저장을 거부할 수 있습니다.
          </p>
          <p>
            쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
            있습니다.
          </p>
        </ul>

        <h2 className="privacy_title">제8조(개인정보 보호책임자)</h2>
        <p>
          회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와
          관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
          개인정보 보호책임자를 지정하고 있습니다.
        </p>

        <ul>
          <li>▶ 개인정보 보호책임자</li>
          <p>성명 : 김경수</p>
          <p>직책 : 개인정보 보호책임자</p>
          <p>연락처 : orora7990@gmail.com</p>
        </ul>

        <p>
          정보주체는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련
          문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및
          담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체
          없이 답변 및 처리해드릴 것입니다.
        </p>

        <h2 className="privacy_title">제9조(개인정보 열람청구)</h2>
        <p>
          정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를
          아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가
          신속하게 처리되도록 노력하겠습니다.
        </p>

        <ul>
          <li>▶ 개인정보 열람청구 접수·처리 부서</li>
          <p>부서명 : 고객지원팀</p>
          <p>담당자 : 김경수</p>
          <p>연락처 : orora7990@gmail.com</p>
        </ul>

        <h2 className="privacy_title">제10조(권익침해 구제방법)</h2>
        <p>
          정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
          문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의
          자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다
          자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
        </p>

        <ul>
          <li>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</li>
          <p>- 소관업무 : 개인정보 침해사실 신고, 상담 신청</p>
          <p>- 홈페이지 : privacy.kisa.or.kr</p>
          <p>- 전화 : (국번없이) 118</p>
          <p>
            - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
            개인정보침해신고센터
          </p>

          <li>▶ 개인정보 분쟁조정위원회</li>
          <p>- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</p>
          <p>- 홈페이지 : www.kopico.go.kr</p>
          <p>- 전화 : (국번없이) 1833-6972</p>
          <p>
            - 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층
          </p>

          <li>▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)</li>
          <li>▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)</li>
        </ul>

        <h2 className="privacy_title">제11조(개인정보 처리방침 변경)</h2>
        <p>
          이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
          변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일
          전부터 공지사항을 통하여 고지할 것입니다.
        </p>

        <p>- 공고일자 : 2023년 6월 1일</p>
        <p>- 시행일자 : 2023년 6월 8일</p>
      </div>
    </div>
  );
}

export default Index;
