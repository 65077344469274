import React, { useState, useEffect } from "react";
import top_header from "assets/images/top_header.svg";
import top_header2 from "assets/images/top_header_2.svg";
import header from "assets/images/header.svg";
import logo from "assets/images/logo1.svg";
function Header() {
  const [isOverlayVisible, setOverlayVisible] = useState(true);

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };
  function hendleClick() {
    alert("Coming soon!");
  }




  // window.dataLayer = window.dataLayer || [];
  // function gtag(){window.dataLayer.push(arguments);}
  // gtag('js', new Date());

  // gtag('config', 'G-39HMBK378C');


  // (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','GTM-W2JB8P7C');

  // window. dataLayer = window.dataLayer || []; 
  // function gtag(){dataLayer.push(arguments);}
  //  gtag('js', new Date()); 
  //  gtag('config', 'G-BRM9TLG8YD');

     window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-BRM9TLG8YD');


  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 20);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>

<script async src="https://www.googletagmanager.com/gtag/js?id=G-BRM9TLG8YD"></script>
      <nav
        className={`navbar navbar-expand-lg navbar-light ${
          isScrolled ? "scrolled" : ""
        }`}
      >


        <div className="container">
        
          <a className="navbar-brand" href="/">
            {" "}
            <img src={logo} alt="" />
          </a>
          <form className="d-flex">
            <a
              href="https://ororabrowser.s3.ap-northeast-2.amazonaws.com/OroraSetup.exe"
              className="main-btn "
            >
              무료 다운로드
              {/* <img src={header} alt="" /> */}
            </a>
            <button onClick={hendleClick} className="main-btn-mobile ">
              무료 다운로드
            </button>

           



          </form>
        </div>
      </nav>
    </>
  );
}

export default Header;
