import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'

function NoAuthLayout() {

	return (
		<>
			
			<div >
				<Header />
				<Outlet />
			</div>
		</>
	)
}

export default NoAuthLayout









